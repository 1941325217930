(function() {

    var cookieModal = $("#cookieNotice");

    cookieModal.modal();

    $('.homeBlock').hover(function(){
        $(this).find('.up').stop(true, true).fadeTo('fast', 0);
        $(this).find('.over').stop(true, true).fadeIn('fast');
        }, function() {
            $(this).find('.over').stop(true, true).fadeOut('fast');
            $(this).find('.up').stop(true, true).fadeTo('fast', 1);
    });

    $.fn.clearForm = function() {
        return this.each(function() {
            var type = this.type, tag = this.tagName.toLowerCase();
            if (tag == 'form')
                return $(':input',this).clearForm();
            if (type == 'text' || type == 'email' || type == 'password' || tag == 'textarea')
                this.value = '';
            else if (type == 'checkbox' || type == 'radio')
                this.checked = false;
            else if (tag == 'select')
                this.selectedIndex = -1;
        });
    };

    $('.trigger-modal').click(function() {
        $('#initiateOrder').clearForm();
    });


    // Retrieve product, update modal content and display modal
    $('.trigger-modal').click(function(e) {

        e.preventDefault();

        $.ajax({
            url: $(this).attr('href'),
            success: function(data) {
                var header = data['service']['title'];
                var title = data['product']['title'] + " <small>- &pound;" + data['product']['price'] + " + VAT</small>";

                $('#BINLabel').empty();
                $('#BINLabel').append(header);

                $('#productTitle').empty();
                $('#productTitle').append(title);

                $('#product_id').val(data['product']['id']);

                // show modal
                $('#startBINProcess').modal();
            }
        });

        return false;

    });

    $('#startBINProcess').on('shown.bs.modal', function() {
        $('.first-form-field').focus();
    });

    $('.dismissModal').click(function() {
        $('#initiateOrder .form-group').removeClass('has-error');
    });

    $('.jumbotron-link').click(function() {
        $.scrollTo('#product-table', 800);
        return false;
    });

    $('.toggle').click(function() {
        $( this ).toggleClass('btn-default btn-primary');
    });


    // Additional information
    // On click make ajax request to establish IF the detail[attribute] has been set
    // If it has then populate the textarea with the content
    // If not then carry on
    $('.additional-information-trigger').click(function(){
        var type = $(this).data('type');
        var legend = $(this).data('legend');
        if (type == 'consent_detail') {
            var modaltitle = 'Specific Consent';
        } else if (type == 'additional_information') {
            var modaltitle = 'Additional information';
        } else {
            var modaltitle = 'N/A';
        }
        var detail = $(this).data('detail');
        var session_id = $('form.additional-information input[name=session_id]').val();

        $.ajax({
          url: '/order/query-order-detail/' + session_id + '/' + detail + '_' + type,
          success: function(data) {
            var response = data['detail'];

            $('#additionalInformationModal .modal-title').empty();
            $('#additionalInformationModal .modal-title').append(modaltitle);

            $('#additionalInformationModal .legend').empty();
            $('#additionalInformationModal .legend').append(legend);

            $('#additionalInformationModal .detail').attr('name', 'detail[' + detail + '_' + type + ']');
            $('#additionalInformationModal .detail').val('');
            if (response != false) {
                $('#additionalInformationModal .detail').val(response);
            } else {
                $('#additionalInformationModal .detail').val('');
            }

            $('#additionalInformationModal').modal();

          },
        });
    });


    // Submit (POST) specific consent detail
    // 1 -> update the order by posting data
    // 2 -> display a 'success' message to user within the modal
    // 3 -> close the modal
    $('form.additional-information').on('submit', function(e) {

        e.preventDefault();

        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        var url = form.prop('action');

        $.ajax({
          type: method,
          url: url,
          data: form.serialize(),
          success: function(data) {

            $('.flash-message').fadeIn(300);

            setTimeout(function() {
                $('#additionalInformationModal').modal('toggle');
                $('.flash-message').fadeOut(300);
            }, 1000);

          }
        });
        return false;

    });


})();